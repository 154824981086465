<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import CustomCardTitle from "@/components/custom-card/custom-card-title.vue";
import WithdrawTokensModal from "@/components/aderlo-cashback/withdraw-tokens-modal.vue";
import Swal from "sweetalert2";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import DepositHistoryTable from "@/components/aderlo-cashback/deposit-history-table.vue";
import DepositTokensModal from "@/components/aderlo-cashback/deposit-tokens-modal.vue";
import TransferTokensModal from "@/components/aderlo-cashback/transfer-tokens-modal.vue";
import WalletHistoryTable from "@/components/aderlo-cashback/wallet-history-table.vue";
import ForecastAccFromPosTable from "@/components/aderlo-cashback/forecast-acc-from-pos-table.vue";
import TransferBnbModal from "@/components/aderlo-cashback/transfer-bnb-modal.vue";

export default {

  components: {
    ForecastAccFromPosTable,
    TransferTokensModal,
    TransferBnbModal,
    Layout,
    PageHeader,
    CustomCardTitle,
    WithdrawTokensModal,
    DepositTokensModal,
    DepositHistoryTable,
    WalletHistoryTable
  },

  data() {
    return {
      intervalId: 0,

      walletDetails: null,
      currentBalance: null,
      proofOfStakeDetails: null,
      proofOfStakeTotalReceived: null,
      aderloCashbackStatistics: null,

      showWalletHistory: true
    }
  },

  computed: {
    availableToDeposit() {
      return this.proofOfStakeDetails.maxTotalSupply - this.proofOfStakeDetails.tokensInProofOfStake;
    },

    yourShareInProofOfStake() {
      if (this.proofOfStakeDetails.tokensInProofOfStake === 0) {
        return 0
      }

      return ((this.currentBalance.currentDepositedAmount / this.proofOfStakeDetails.tokensInProofOfStake) * 100);
    },

    getLastWeekTokens() {
      return this.proofOfStakeTotalReceived.fromLastWeek > 0 ? '+' + this.proofOfStakeTotalReceived.fromLastWeek : this.proofOfStakeTotalReceived.fromLastWeek;
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 18}).format(value);
    }
  },

  methods: {
    getBalance(tokenSymbol) {
      if (!this.walletDetails) {
        return 0
      }

      const balanceData = this.walletDetails.balanceData.balances.find(element => element.symbol === tokenSymbol)
      return balanceData ? balanceData.balance : 0
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Aderlo Cashback",
          active: true
        }
      ]
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        await Swal.fire("", "Skopiowano Adres Portfela!", "success");
      } catch (error) {
        console.error(error);
      }
    },

    async loadCurrentBalance() {
      try {
        const {data} = await axios.get(`/user/cryptocurrency/proof-of-stake/current-balance/ACC`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.currentBalance = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWalletDetails() {
      try {
        const {data} = await axios.get(`/user/cryptocurrency/wallet/details`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.walletDetails = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProofOfStakeDetails() {
      try {
        const {data} = await axios.get(`/user/cryptocurrency/proof-of-stake/details/ACC`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.proofOfStakeDetails = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProofOfStakeTotalReceived() {
      try {
        const {data} = await axios.get(`/user/cryptocurrency/proof-of-stake/total-received/ACC`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.proofOfStakeTotalReceived = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAderloCashbackStatistics() {
      try {
        const {data} = await axios.get(`/aderlo/cashback/statistics`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.aderloCashbackStatistics = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async initAll() {
      await Promise.all([
        this.loadCurrentBalance(),
        this.loadWalletDetails(),
        this.loadProofOfStakeDetails(),
        this.loadProofOfStakeTotalReceived(),
        this.loadAderloCashbackStatistics()
      ]);

      const that = this
      this.intervalId = setInterval(async () => {
        await Promise.all([
          that.loadCurrentBalance(),
          that.loadWalletDetails(),
          that.loadProofOfStakeDetails(),
          that.loadProofOfStakeTotalReceived(),
          that.loadAderloCashbackStatistics()
        ]);
      }, 5000);
    }
  },

  async created() {
    await this.initAll()
  },

  beforeDestroy() {
    if (!this.intervalId) {
      return
    }

    clearInterval(this.intervalId);
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Aderlo Cashback" :items="getItems()"/>

    <div class="row mt-3">
      <div class="col-12 w-100">
        <div style="overflow: hidden; border-radius: 30px;">
          <iframe height="800px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src="https://www.geckoterminal.com/bsc/pools/0x81d51e194a51b589c9877633a891d5bb99ec2e2a?embed=1&info=1&swaps=1" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <div class="row cashback-header mt-3" v-if="walletDetails">
      <div class="col-lg-12">
        <div class="row p-0 align-items-center">
          <div class="col-7">
            <div class="row pb-1">
              <div class="col-8 p-0 pr-1">
                <div class="cashback-header p-0 d-flex flex-row align-items-center">
                  <div class="cashback-icon-box d-flex align-items-center justify-content-center">
                    <img src="@/assets/images/brands/aderlo-cashback.svg" alt="Aderlo Cashback" class="cashback-icon"/>
                  </div>

                  <div class="flex-grow-1 pl-2">
                    <span class="font-size-14 font-weight-light">
                      {{$t('aderlo-cashback.your-available-acc-token') }}
                    </span>
                    <i id="token-acc-description"
                       class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                    <b-tooltip target="token-acc-description" placement="right">{{
                        $t('aderlo-cashback.acc-token-description')
                      }}
                    </b-tooltip>
                    <div class="font-weight-bold font-size-30 color-secondary">{{
                        getBalance('ACC') | formatEthBalance
                      }}
                    </div>
                  </div>

                  <div class="float-right pr-2 d-flex flex-column" v-if="proofOfStakeTotalReceived">
                    <div class="font-size-14 font-weight-light">ostatni tydzień:</div>
                    <div class="custom-badge" :class="proofOfStakeTotalReceived.fromLastWeek > 0 ? 'custom-badge-green' : 'custom-badge-red'">
                      {{ getLastWeekTokens | formatEthBalance }} ACC
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-4 p-0 pl-1">
                <div class="cashback-header ">
                  <div class="color-primary font-size-14">{{ $t('aderlo-cashback.bnb-amount')}}
                  </div>
                  <div class="color-secondary font-size-30 font-weight-bold">
                    {{  getBalance('BNB') | formatEthBalance }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row pt-1">
              <div class="col-12 d-flex align-items-center justify-content-between cashback-header py-1">
                <div class="d-flex flex-row align-items-center">
                <label class="m-0">{{ $t('aderlo-cashback.wallet-address') }}</label>
                <div class="pl-2 color-secondary"> {{ walletDetails.wallet.address }}</div>
                <i class="ri-file-copy-line font-size-18 icon-copy"
                   @click="copyToClipboard(walletDetails.wallet.address)"></i>
                </div>
                <div>
                  <a @click="showWalletHistory = !showWalletHistory" class="btn btn-outline-primary w-100 py-1"
                     style="text-decoration: underline">{{
                      $t(showWalletHistory ? 'aderlo-cashback.deposit-history' : 'aderlo-cashback.wallet-history')
                    }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1"/>
          <div class="col-4">
            <b-button @click="$refs.transferTokensModal.openModal()" variant="success" class="w-100">
              {{ $t('aderlo-cashback.transfer-tokens') }}
            </b-button>
            <a href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xC513CaEC0d339535C1EdA50d8E2CA4BC431eC493"
               target="_blank" class="btn btn-primary w-100 my-2">
              {{ $t('aderlo-cashback.buy-tokens') }}
            </a>
            <a href="https://pancakeswap.finance" target="_blank" class="btn btn-outline-primary w-100">
              {{ $t('aderlo-cashback.exchange-bnb') }}
            </a>

            <b-button  @click="$refs.transferBNBModal.openModal()" variant="success" class="w-100 my-2">
              Wypłać BNB
            </b-button>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="text-center">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
      </div>
    </div>

    <div class="row mt-3" v-if="currentBalance && proofOfStakeDetails">
      <div class="col-xl-6 pr-2">
        <div class="cashback-container-header">
          <div class="d-flex align-items-center cashback-box cashback-top" style="border-radius: 5px 5px 0 0">
            <div class="col-6 p-0">
              <div class="color-primary font-size-14">{{
                  $t('aderlo-cashback.deposit.available-deposited-tokens')
                }}
              </div>
              <div class="color-secondary font-size-32 font-weight-bold">
                {{ currentBalance.currentDepositedAmount | formatEthBalance }} ACC
              </div>
            </div>
            <div class="col-6 p-0">
              <b-button @click="$refs.depositTokensModal.openModal()" variant="primary" class="w-100 col-6 float-right">
                {{ $t('aderlo-cashback.deposit.deposit-tokens') }}
              </b-button>
            </div>
          </div>
          <div class="d-flex align-items-center cashback-box cashback-bottom">
            <div class="col-6 p-0">
              <div class="color-primary font-size-12">{{
                  $t('aderlo-cashback.deposit.available-balance-to-withdraw')
                }}
              </div>
              <div class="color-secondary font-size-14 font-weight-bold">
                {{ currentBalance.availableBalanceToWithdraw | formatEthBalance }} ACC
              </div>
            </div>
            <div class="col-6 p-0">
              <b-button @click="$refs.withdrawTokensModal.openModal()" variant="outline-primary"
                        class="w-100 col-12 float-right">
                {{ $t('aderlo-cashback.deposit.withdraw-tokens') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="cashback-container-body">
          <div class="d-flex align-items-center" v-if="proofOfStakeTotalReceived">
            <div class="col-12 text-center cashback-box-acc px-0">
              <span class="color-primary font-size-14">{{ $t('aderlo-cashback.deposit.received-acc-from-pos') }}</span>&nbsp;
              <span class="color-primary font-size-14 font-weight-bold text-green">{{ proofOfStakeTotalReceived.total | formatEthBalance }} ACC</span>
            </div>
          </div>

          <div class="row m-0">
            <div class="d-flex justify-content-center cashback-box col-6 flex-column">
              <div class="color-primary font-size-12">{{ $t('aderlo-cashback.pos.tokens-in-proof-of-stake') }}</div>
              <div class="color-secondary font-size-14 font-weight-bold">
                {{ proofOfStakeDetails.maxTotalSupply | formatEthBalance }} ACC
              </div>
            </div>
            <div class="d-flex justify-content-center cashback-box col-6 flex-column">
              <div class="color-primary font-size-12">{{ $t('aderlo-cashback.pos.your-share-in-proof-of-stake') }}</div>
              <div class="color-secondary font-size-14 font-weight-bold">{{ yourShareInProofOfStake }}%</div>
            </div>
          </div>
          <div class="row m-0">
            <div class="d-flex justify-content-center cashback-box col-6 flex-column"
                 style="border-bottom-left-radius: 5px">
              <div class="color-primary font-size-12">{{ $t('aderlo-cashback.pos.tokens-in-pool') }}</div>
              <div class="color-secondary font-size-14 font-weight-bold">
                {{ proofOfStakeDetails.tokensInProofOfStake | formatEthBalance }} ACC
              </div>
            </div>
            <div class="d-flex justify-content-center cashback-box col-6 flex-column"
                 style="border-bottom-right-radius: 5px">
              <div class="color-primary font-size-12">{{ $t('aderlo-cashback.pos.available-to-deposit') }}</div>
              <div class="color-secondary font-size-14 font-weight-bold">{{ availableToDeposit | formatEthBalance }}
                ACC
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-12 pl-2">
        <div class="card">
          <custom-card-title
              :title="$t(showWalletHistory ? 'aderlo-cashback.wallet-history' : 'aderlo-cashback.deposit-history')"/>
          <div class="card-body">
            <template v-if="showWalletHistory">
              <wallet-history-table/>
            </template>
            <template v-else>
              <deposit-history-table/>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <custom-card-title title="Aderlo Cashback live" />
          <div class="card-body">
            <div class="row d-flex" style="gap: 20px" v-if="aderloCashbackStatistics">
              <div class="details-box text-center">
                <div class="color-primary font-size-12">Ilość transakcji Cashback z PancakeSwap:
                </div>
                <div class="color-secondary font-size-14 font-weight-bold">
                  {{  aderloCashbackStatistics.aderloCashbackCount }}
                </div>
              </div>
              <div class="details-box text-center">
                <div class="color-primary font-size-12">Ilość ACC wydanych klientom:
                </div>
                <div class="color-secondary font-size-14 font-weight-bold">
                  {{ aderloCashbackStatistics.accBought | formatEthBalance }} ACC
                </div>
              </div>
              <div class="details-box text-center">
                <div class="color-primary font-size-12">Całkowita pula tokenów w PoS od początku:
                </div>
                <div class="color-secondary font-size-14 font-weight-bold">
                  {{ aderloCashbackStatistics.totalTokenPoolInProofOfStakeFromTheBeginning | formatEthBalance }} ACC
                </div>
              </div>
              <div class="details-box text-center">
                <div class="color-primary font-size-12">Obecnie prognozowana pula PoS:
                </div>
                <div class="color-secondary font-size-14 font-weight-bold">
                  {{ aderloCashbackStatistics.currentForecastedTokenPoolInProofOfStake | formatEthBalance }} ACC
                </div>
              </div>
              <div class="details-box text-center">
                <div class="color-primary font-size-12">Twój udział w obecnie prognozowanej puli PoS:
                </div>
                <div class="color-secondary font-size-14 font-weight-bold">
                  {{ aderloCashbackStatistics.yourShareInCurrentForecastedTokenPoolInProofOfStake | formatEthBalance }} ACC
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <custom-card-title title="Prognozy ACC z PoS" />
          <div class="card-body">
            <forecast-acc-from-pos-table />
          </div>
        </div>
      </div>
    </div>

    <withdraw-tokens-modal v-if="currentBalance && walletDetails" ref="withdrawTokensModal"
                           :current-balance="currentBalance" :internal-wallet="walletDetails.wallet.address"/>
    <deposit-tokens-modal v-if="walletDetails" ref="depositTokensModal" :balance="getBalance('ACC')"
                          :internal-wallet="walletDetails.wallet.address"/>
    <transfer-tokens-modal v-if="walletDetails" ref="transferTokensModal" :balance="getBalance('ACC')" :internal-wallet="walletDetails.wallet.address"/>
    <transfer-bnb-modal v-if="walletDetails" ref="transferBNBModal" :balance="getBalance('BNB')" :internal-wallet="walletDetails.wallet.address" />
  </Layout>
</template>
<style>
.cashback-header {
  padding: 10px 20px;
  margin: 0;
  height: 100%;
  border-radius: 5px;
  background: rgba(72, 146, 241, 0.1);
}

.custom-badge {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
  width: fit-content;
}

.custom-badge-green {
  background: rgba(18, 142, 108, 0.25);
  color: rgba(34, 180, 140, 1);
}

.text-green {
  color: #1CBB8C;
}

.custom-badge-red {
  background: rgba(241, 72, 72, 0.25);
  color: rgba(180, 34, 34, 1);
}

.cashback-box {
  padding: 15px 20px;
}

.cashback-container-header {
  border-radius: 5px 5px 0 0;
}

.cashback-container-body {
  border-radius: 0 0 5px 5px;
}

.cashback-box-acc {
  padding: 15px 0;
  margin: 0;
  background: rgba(28, 187, 140, 0.15);
}



.details-box {
  flex: 1 1 0;
  border-radius: 5px;
  padding: 20px 0;
  background: rgba(42, 49, 62, 1);
}

.icon-copy {
  color: #E7EAF4;
  padding-left: 15px;
  cursor: pointer;
}

.cashback-icon-box {
  border-radius: 5px;
  background: rgba(72, 146, 241, 0.1);
  width: 90px;
  height: 90px;
}

</style>