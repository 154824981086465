<template>
  <ecat-table
      ref="table"
      :fields="getFields()"
      :items="loadAderloCashback"
      :pagination-top="true"
      :table="table">
    <template v-slot:shop="{ item }">
      <span class="text-primary">{{ item.shopDomain ? item.shopDomain : item.shopName }}</span>
    </template>

    <template v-slot:product="{ item }">
      <p v-for="(orderProduct, index) in getOrderProducts(item.shopOrderId)" :key="index">
        {{ orderProduct.name }}
      </p>
    </template>

    <template v-slot:ean="{ item }">
      <p v-for="(orderProduct, index) in getOrderProducts(item.shopOrderId)" :key="index">
        <render-product-eans :product="getProductVariation(orderProduct.productVariationId)" />
      </p>
    </template>

    <template v-slot:priceHigh="{ item }">
      <p v-for="(orderProduct, index) in getOrderProducts(item.shopOrderId)" :key="index">
        {{ priceHelper.format(getProductVariation(orderProduct.productVariationId).priceMarginTaxIncluded, $store.getters["market/getMarketCurrency"](item.marketId)) }}
      </p>
    </template>

    <template v-slot:amount="{ item }">
      <span>{{ item.amount | formatEthBalance }} ACC</span>
    </template>

    <template v-slot:userShareInProofOfStake="{ item }">
      <span>{{ getUserProofOfStakeInformation(item.id).share }}%</span>
    </template>

    <template v-slot:forecastedAmountFromProfit="{ item }">
      <span class="text-success">{{ getForecastedAmountFromProfit(item) | formatEthBalance }} ACC</span>
    </template>

    <template v-slot:createdAt="{ item }">
      <span class="text-primary">{{ dateUtil.asDateTimeNormal(item.createdAt) }}</span>
    </template>
  </ecat-table>
</template>

<script>
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import BigNumber from "bignumber.js";
import {errorCatcher} from "@/helpers/error-catcher";
import RenderProductEans from "@/components/render-product-eans.vue";
import {priceHelper} from "@/helpers/price-helper";

export default {
  components: {RenderProductEans},

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100, 200],
        filter: null,
        filterOn: [],
        sortBy: "id",
        dbSortBy: "",
        sortDesc: false,
        submitted: false,
        inputPage: "",

        items: []
      },

      shopOrderProducts: new Map(),
      userProofOfStakeInformations: new Map()
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 18 }).format(value);
    }
  },

  computed: {
    priceHelper() {
      return priceHelper
    },
    dateUtil() {
      return dateUtil
    }
  },

  methods: {
    getFields() {
      return [
        {key: "shopOrderIdAsNumber", label: "Numer zamówienia:" },
        {key: "shop", slot: true, label: "Nazwa sklepu:" },
        {key: "product", slot: true, label: "Produkt:" },
        {key: "ean", slot: true, label: "EAN:" },
        {key: "priceHigh", slot: true, label: "Cena sklepu brutto:" },
        {key: "amount", slot: true, label: "Całkowity Zysk:" },
        {key: "userShareInProofOfStake", slot: true, label: "% z Zysku:" },
        {key: "forecastedAmountFromProfit", slot: true, label: "Przewidywana kwota z Zysku:" },
        {key: "createdAt", slot: true, label: "Data:" },
      ]
    },

    async loadAderloCashback() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        // let orderBy = {}
        // if (this.table.dbSortBy) {
        //   orderBy = JSON.stringify({
        //     [this.table.dbSortBy]: this.table.sortDesc ? "DESC" : "ASC"
        //   })
        // }

        const {data} = await axios.get(`/aderlo/cashback`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter,
            // orderBy: orderBy,
          }
        });

        this.userProofOfStakeInformations = new Map(data.userProofOfStakeInformations.map((obj) => [obj.aderloCashbackId, obj]));
        this.table.items = data.data
        this.table.rows = data.count
        this.table.totalRows = data.count

        await this.loadShopOrderProducts()
        await this.loadProductVariationsByIds()
      } catch (error) {
        this.table.items = []
      }

      return this.table.items
    },

    getForecastedAmountFromProfit(item) {
      const userProofOfStakeInformation = this.getUserProofOfStakeInformation(item.id)
      const totalProfit = new BigNumber(item.amount)
      const forecastedAmountFromProfit = totalProfit
          .multipliedBy(new BigNumber(0.5))
          .multipliedBy(new BigNumber(userProofOfStakeInformation.share).div(100))

      return forecastedAmountFromProfit.toString()
    },

    async loadShopOrderProducts() {
      try {
        if (this.table.items.length === 0) {
          return;
        }

        const ids = [];
        for (let aderloCashback of this.table.items) {
          ids.push(aderloCashback.shopOrderId);
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/shop-order/product/fetch/by/shop-order`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shopOrderProducts = new Map();
        for (const id of ids) {
          const orderProducts = []
          for (const orderProduct of data) {
            if (orderProduct.shopOrderId === id) {
              orderProducts.push(orderProduct)
            }
          }

          this.shopOrderProducts[id] = orderProducts
        }
      } catch (error) {
        console.log(error)
      }
    },

    async loadProductVariationsByIds() {
      try {
        const productVariationIds = [];
        for (const key in this.shopOrderProducts) {
          const orderProducts = this.shopOrderProducts[key]
          if (orderProducts) {
            productVariationIds.push(...orderProducts.map(orderProduct => orderProduct.productVariationId))
          }
        }

        const json = {
          ids: productVariationIds
        }

        const { data } = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.productVariations = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getOrderProducts(id) {
      return this.shopOrderProducts[id]
    },

    getProductVariation(productVariationId) {
      if (!this.productVariations) {
        return null
      }

      return this.productVariations.get(productVariationId)
    },

    getUserProofOfStakeInformation(aderloCashbackId) {
      if (!this.userProofOfStakeInformations) {
        return { share: 0 }
      }

      return this.userProofOfStakeInformations.get(aderloCashbackId) || { share: 0 }
    }
  }

}
</script>